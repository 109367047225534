import { traverseTerminologyInfo } from './traverseTerminologyInfo'
import { TTerminology } from './TTerminology'

import terminologyInfo from '../custom-terminology-metadata/terminology.json'
const TERMINOLOGY_INFO = terminologyInfo.data as unknown as TTerminology

let terminologyMessageKeys: string[] | undefined
export function getTerminologyMessageKeys(): string[] {
  if (!terminologyMessageKeys) {
    const keys: string[] = []
    traverseTerminologyInfo(TERMINOLOGY_INFO, (name) => {
      keys.push(name[0], name[1])
    })
    terminologyMessageKeys = keys
  }
  return terminologyMessageKeys
}

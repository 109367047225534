export function areArraysEqual<T>(prevDeps: T[], nextDeps: T[]): boolean {
  if (prevDeps === nextDeps) {
    return true
  }
  if (prevDeps.length !== nextDeps.length) {
    return false
  }
  for (let i = 0; i < prevDeps.length; i++) {
    if (!Object.is(nextDeps[i], prevDeps[i])) {
      return false
    }
  }
  return true
}

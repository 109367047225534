import type { ICachedValue, ICacheQueryResult, IAsyncCache, Tuple } from '@workfront/localize'
import { MultiKeyMap } from '@workfront/localize'

const DB_NAME = 'wfLocalizeDB'
const DB_VERSION = 1
const DB_STORE_NAME = 'cache'

// here we cache result of isIndexedDBSupported check
let isIndexedDBSupportedPromise: Promise<boolean>

/**
 * IndexedDB is not supported in Firefox Private Browsing mode.
 *
 * Some IndexedDB features are not supported under IE.
 * For more details about IE support, see here:
 *   https://gist.github.com/nolanlawson/a841ee23436410f37168
 *   https://nolanlawson.s3.amazonaws.com/pouchdb/www/20141025/10things2/10things.html
 * and
 *   https://indexdb-support-test.glitch.me
 */
export function isIndexedDBSupported(): Promise<boolean> {
  if (!isIndexedDBSupportedPromise) {
    isIndexedDBSupportedPromise = new Promise<boolean>((resolve) => {
      // Typescript does not know about non-standard msIndexedDB property on a window object
      // @ts-expect-error: TS2551
      if (!!indexedDB && !window.msIndexedDB) {
        // Although window.indexedDB exists, IndexedDB implementations differ for each browser.
        // Thus, we try to execute common cache operations and see if we'll get any errors.
        // If there will be errors during the process, we'll assume that IndexedDB is not supported.
        const cache = new IndexedDBCache()
        cache
          .open()
          .then(() => cache.get('test', 'test', ['test']))
          .then(() => cache.close())
          .then(() => resolve(true))
          .catch(() => resolve(false))
      } else {
        resolve(false)
      }
    })
  }
  return isIndexedDBSupportedPromise
}

export class IndexedDBCache implements IAsyncCache {
  protected _db: IDBDatabase | undefined
  protected _isOpen: boolean
  protected _removeEventListeners: Array<() => void>
  protected _runningGetRequests: MultiKeyMap<
    3,
    string,
    Promise<(cacheResult: ICacheQueryResult) => void>
  >

  constructor() {
    this._isOpen = false
    this._db = undefined
    this._removeEventListeners = []
    this._runningGetRequests = new MultiKeyMap(3)
  }

  open(): Promise<void> {
    const enablePageLifecycleListeners = () => {
      // Close cache when page unloads to avoid IndexedDB exceptions and to enable back-forward cache.
      // See https://web.dev/bfcache/
      // and https://developers.google.com/web/updates/2018/07/page-lifecycle-api#developer-recommendations-for-each-state
      const options = { capture: true }
      const listener = () => {
        this.close()
      }
      ;['freeze', 'pagehide'].forEach((type) => {
        window.addEventListener(type, listener, options)
        this._removeEventListeners.push(() => {
          window.removeEventListener(type, listener, options)
        })
      })
    }

    return new Promise<void>((resolve, reject) => {
      const openRequest = indexedDB.open(DB_NAME, DB_VERSION)
      openRequest.onblocked = () => {
        reject(
          new Error('IndexedDB open blocked. Please close all other tabs with this site open!')
        )
      }
      attachOnError(openRequest, reject)

      let isUpgrading = false
      openRequest.onsuccess = () => {
        if (!isUpgrading) {
          this._db = openRequest.result

          // 'onversionchange' happens when another browser tab requests database upgrade.
          // we should close db in order to let this tab to proceed
          this._db.onversionchange = () => {
            this.close()
          }
          this._db.onclose = () => {
            this._db = undefined // no need to close db, just need to run remaining cleanup
            this.close()
          }
          resolve()
        }
      }

      openRequest.onupgradeneeded = (evt) => {
        isUpgrading = true
        const _db = openRequest.result
        _db.onversionchange = () => _db.close()
        try {
          if (evt.oldVersion !== 0) {
            _db.deleteObjectStore(DB_STORE_NAME)
          }
          const store = _db.createObjectStore(DB_STORE_NAME, {
            keyPath: ['namespace', 'locale', 'messageKey'],
            autoIncrement: false,
          })
          ;['namespace', 'locale', 'messageKey', 'message', 'expiresAt'].forEach((el) =>
            store.createIndex(el, el, { unique: false })
          )
          store.transaction.oncomplete = () => {
            isUpgrading = false
          }
        } catch (e) {
          reject(e)
        }
      }
    })
      .then(enablePageLifecycleListeners)
      .then(() => {
        this._isOpen = true
      })
  }

  close(): Promise<void> {
    if (this._db) {
      this._db.close()
    }
    this._isOpen = false
    this._removeEventListeners.forEach((listener) => listener())
    return Promise.resolve()
  }

  get(namespace: string, locale: string, messageKeys: string[]): Promise<ICacheQueryResult> {
    return this._withOpenCache(() => {
      const now = +new Date()
      return new Promise<ICacheQueryResult>((resolve, reject) => {
        const promises = messageKeys.map((messageKey) => {
          const key: Tuple<string, 3> = [namespace, locale, messageKey]
          return this._runningGetRequests.ensure(key, () => {
            return new Promise((resolve, reject) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const store = createTransaction(this._db!, 'readonly', undefined, reject)
              if (!store) {
                return
              }
              withGetResult<ICachedValue | undefined>(store, key, (record) => {
                this._runningGetRequests.remove(key)
                resolve((cacheQueryResult: ICacheQueryResult) => {
                  if (typeof record !== 'undefined') {
                    cacheQueryResult.hits[messageKey] = {
                      message: record.message,
                      expiresAt: record.expiresAt,
                    }
                    if (record.expiresAt < now) {
                      cacheQueryResult.expiredKeys.push(messageKey)
                    }
                  } else {
                    cacheQueryResult.missingKeys.push(messageKey)
                  }
                })
              })
            })
          })
        })
        Promise.all(promises).then((cacheQueryResultsModifiers) => {
          const cacheQueryResult: ICacheQueryResult = {
            hits: {},
            expiredKeys: [],
            missingKeys: [],
          }
          for (const cacheQueryResultsModifier of cacheQueryResultsModifiers) {
            cacheQueryResultsModifier(cacheQueryResult)
          }
          resolve(cacheQueryResult)
        }, reject)
      })
    })
  }

  set(
    namespace: string,
    locale: string,
    messages: Record<string, string>,
    expiresAt: number
  ): Promise<void> {
    return this._withOpenCache(
      () =>
        new Promise((resolve, reject) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const store = createTransaction(this._db!, 'readwrite', resolve, reject)
          if (!store) {
            return
          }
          Object.keys(messages).forEach((messageKey) => {
            store.put({
              namespace,
              locale,
              messageKey,
              message: messages[messageKey],
              expiresAt,
            })
          })
        })
    )
  }

  remove(namespace: string, locale: string, messageKeys: string[]): Promise<void> {
    return this._withOpenCache(
      () =>
        new Promise((resolve, reject) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const store = createTransaction(this._db!, 'readwrite', resolve, reject)
          if (!store) {
            return
          }
          messageKeys.forEach((messageKey) => store.delete([namespace, locale, messageKey]))
        })
    )
  }

  isOpen(): boolean {
    return this._isOpen
  }

  protected _withOpenCache<T>(cb: () => Promise<T>): Promise<T> {
    return this.isOpen() ? cb() : this.open().then(cb)
  }
}

function createTransaction(
  db: IDBDatabase,
  mode: 'readonly' | 'readwrite',
  onComplete: (() => void) | undefined,
  onError: (e: unknown) => void
): IDBObjectStore | undefined {
  try {
    const options = {
      durability: 'relaxed',
    }
    // @ts-expect-error TS2554  3rd `options` argument is missing from db.transaction types
    const store = db.transaction(DB_STORE_NAME, mode, options).objectStore(DB_STORE_NAME)
    if (onComplete) {
      store.transaction.oncomplete = onComplete
    }
    attachOnError(store.transaction, onError)
    return store
  } catch (e) {
    onError(e)
  }
}

function withGetResult<R>(store: IDBObjectStore, key: IDBValidKey, cb: (result: R) => void): void {
  const getReq = store.get(key)
  getReq.onsuccess = () => {
    cb(getReq.result)
  }
}

function attachOnError(req: IDBRequest | IDBTransaction, reject: (e: Error) => void) {
  req.onerror = (evt) =>
    reject(
      // In this case evt.target and evt.target.error are always defined
      // @ts-expect-error TS2531
      new Error((evt.target as IDBRequest | IDBTransaction).error.message)
    )
}

import {
  Interpolator,
  SharedInMemoryCache,
  AbstractTerminologyEnabledClient,
  TTerminologyLabels,
  TTerminology,
} from '@workfront/localize'

import type { IPostProcessor, TAbstractTerminologyEnabledClientOptions } from '@workfront/localize'

import { FetchTransport, TFetchTransportOptions } from './FetchTransport'
import { isIndexedDBSupported, IndexedDBCache } from './IndexedDBCache'
import { HtmlPostProcessor } from './HtmlPostProcessor'
import { makeTimeBoundedPromise } from './makeTimeBoundedPromise'

import { getXSRFToken } from 'workfront-cookie'
import terminologyInfo from './custom-terminology-metadata/terminology.json'
import { IAsyncCache, TTerminologyInterpolatorOptions } from '@workfront/localize'
import { ITransport } from '@workfront/localize/src'
import { IWindowWithVariables } from './IWindowWithVariables'
const TERMINOLOGY_INFO = terminologyInfo.data as unknown as TTerminology

export type TBrowserClientOptions = Omit<TAbstractTerminologyEnabledClientOptions, 'transport'> &
  Partial<Pick<TAbstractTerminologyEnabledClientOptions, 'transport'>> & {
    /**
     * Which url to use for connecting to localizer microservice.
     * If you omit this option, window.WF_LOCALIZER_URL global variable will be used.
     * Otherwise `url` option will override window.WF_LOCALIZER_URL variable.
     * Defaults to string '/localizer'.
     */
    url?: string

    /**
     * Which hostname to use for connecting to redrock for API data.
     * If you omit this option, window.WF_API_ENDPOINT_HOSTNAME global variable will be used.
     * Otherwise, a network request will simply be made at the same hostname
     * as the current browser location.
     */
    workfrontApiHostname?: string

    /**
     * A duration transport waits collecting requests in a buffer
     * until making actual call to transport for unbuffered transfer
     */
    flushDelayMs?: number

    /**
     * Use {@link https://web.dev/trusted-types/ Trusted Types} in case browser supports them.
     */
    useTrustedTypes?: boolean
  }

const DEFAULT_TRANSPORT_FLUSH_DELAY = 100

let sharedTransport: ITransport
function getTransportInstance(options: TFetchTransportOptions): ITransport {
  // When all options have their default values, we use shared transport.
  // That way we'll use a single transport instance in most cases, which means less network calls.
  if (!options.url && options.flushDelayMs === DEFAULT_TRANSPORT_FLUSH_DELAY) {
    if (!sharedTransport) {
      sharedTransport = new FetchTransport(options)
    }
    return sharedTransport
  }
  return new FetchTransport(options)
}

export class BrowserClient extends AbstractTerminologyEnabledClient implements EventTarget {
  /**
   * Name of post-processor chain which includes HtmlPostProcessor
   */
  static HTML: 'HTML'

  /**
   * This is used as a default in case client instance cannot be determined.
   * Currently used only in stubs.
   */
  static defaultInstance?: BrowserClient

  protected _htmlPostProcessor: HtmlPostProcessor
  protected _eventTarget: EventTarget
  protected static _terminologyLabelsLoadingPromise?: Promise<TTerminologyLabels>
  protected _workfrontApiHostname: string

  constructor(options: TBrowserClientOptions) {
    const {
      url,
      useTrustedTypes,
      transport,
      flushDelayMs = DEFAULT_TRANSPORT_FLUSH_DELAY,
      workfrontApiHostname,
      enableTerminology,
      ...otherOptions
    } = options
    super({
      ...otherOptions,
      enableTerminology: (window as IWindowWithVariables)?.WF_LOCALIZER_PUBLIC
        ? false
        : enableTerminology,
      transport:
        transport ||
        getTransportInstance({
          url,
          flushDelayMs,
        }),
    })
    this._eventTarget = document.createDocumentFragment()
    this._eventTarget.addEventListener('error', this.handleError)
    this._eventTarget.addEventListener('messageKeyMissing', this.handleMessageKeyMissing)
    this._htmlPostProcessor = new HtmlPostProcessor({
      returnTrustedType: !!useTrustedTypes,
    })
    this._workfrontApiHostname =
      workfrontApiHostname || (window as IWindowWithVariables).WF_API_ENDPOINT_HOSTNAME || ''
  }

  protected createCache(): Promise<IAsyncCache> {
    return isIndexedDBSupported().then((isSupported) => {
      return isSupported ? new IndexedDBCache() : new SharedInMemoryCache()
    })
  }

  static getBrowserLocale(): string {
    const locale = document.documentElement.lang || navigator.language

    // RedRock uses `en_US` as document.documentElement.lang,
    // which is not a valid language tag, here we fix it.
    return locale.replace('_', '-')
  }

  getDefaultLocale(): string {
    return BrowserClient.getBrowserLocale()
  }

  /**
   * Load `messageKey` (querying cache and transport), store it in internal store,
   * interpolate with provided arguments and return the result
   */
  getText(messageKey: string, fallback: string, ...args: unknown[]): Promise<string> {
    return super.get(Interpolator.DEFAULT, messageKey, fallback, ...args)
  }

  /**
   * Locate `messageKey` in internal store, interpolate with provided arguments
   * and return the result, refreshing cache in a background
   */
  getTextSync(messageKey: string, fallback: string, ...args: unknown[]): string {
    return super.getSync(Interpolator.DEFAULT, messageKey, fallback, ...args)
  }

  /**
   * Load `messageKey` (querying cache and transport), store it in internal store,
   * interpolate with provided arguments and return the result as a valid HTML
   */
  getHtml(messageKey: string, fallback: string, ...args: unknown[]): Promise<string> {
    return super.get(BrowserClient.HTML, messageKey, fallback, ...args)
  }

  /**
   * Locate `messageKey` in internal store, interpolate with provided arguments
   * and return the result as a valid HTML, refreshing cache in a background
   */
  getHtmlSync(messageKey: string, fallback: string, ...args: unknown[]): string {
    return super.getSync(BrowserClient.HTML, messageKey, fallback, ...args)
  }

  /**
   * Load terminology labels from server if terminology is enabled
   */
  loadTerminologyLabels(): Promise<TTerminologyLabels> {
    // For WF public pages, terminology labels do not work since the public token is not mapped to a layout template
    if ((window as IWindowWithVariables).WF_LOCALIZER_PUBLIC) {
      return Promise.resolve({})
    }
    if (!BrowserClient._terminologyLabelsLoadingPromise) {
      const matches = document.cookie.match(/\bXSRF-TOKEN=([a-zA-Z\d]+)\b/)
      const currentSessionToken = matches ? matches[1] : undefined
      BrowserClient._terminologyLabelsLoadingPromise = this.getCache()
        .then((cache) => cache.get('__$$customTerminology', '', ['labels']))
        .then((cacheResult) => {
          const cachedValue = cacheResult.hits['labels']
          if (cachedValue && cachedValue.message && !cacheResult.expiredKeys.includes('labels')) {
            const data = JSON.parse(cachedValue.message)
            // use cache only if user's session was not changed
            if (data.sessionToken === currentSessionToken) {
              return data.labels || {}
            }
          }

          const abortController = new AbortController()
          return makeTimeBoundedPromise(
            fetch(
              `${this._workfrontApiHostname}/attask/api-internal/UITMPL?action=getActualTemplate&updates={"fields":["terminology"]}`,
              {
                method: 'PUT',
                credentials: 'same-origin',
                headers: getHeaders(),
                signal: abortController.signal,
              }
            ),
            {
              timeoutRejectValue: 'timeout loading terminology labels',
              abort: () => abortController.abort(),
            }
          )
            .then((response) => {
              if (response.ok) {
                return response.json()
              } else if ([502, 503].includes(response.status)) {
                return {
                  data: { result: {} },
                }
              } else {
                throw new Error(
                  `Failed to load terminology labels. ${response.status} - ${response.statusText}`
                )
              }
            })
            .then((response) => {
              if (response.data.result.terminology) {
                return response.data.result.terminology.terms || {}
              }
              return {}
            })
            .then((terms) => {
              const labels: TTerminologyLabels = {}
              Object.keys(terms)
                .filter(isKnownTerm)
                .forEach((termType) => {
                  const model = terms[termType]
                  labels[termType] = model.value
                    ? model.value
                    : {
                        singular: model.singular,
                        plural: model.plural,
                      }
                })
              return this.getCache()
                .then((cache) =>
                  cache.set(
                    '__$$customTerminology',
                    '',
                    {
                      labels: JSON.stringify({
                        labels,
                        sessionToken: currentSessionToken,
                      }),
                    },
                    +new Date() + 7 * 24 * 60 * 60 * 1000 //cache for 7 days
                  )
                )
                .then(() => labels)
            })
            .catch((error) => {
              this.emitEvent('error', {
                op: 'loadingTerminologyLabels',
                message: 'terminology labels loading error',
                error,
              })
              return {}
            })
        })
    }
    return BrowserClient._terminologyLabelsLoadingPromise
  }

  addEventListener(
    type: string,
    listener: EventListener | EventListenerObject | null,
    options?: boolean | AddEventListenerOptions
  ): void {
    this._eventTarget.addEventListener(type, listener, options)
  }

  removeEventListener(
    type: string,
    listener: EventListener | EventListenerObject | null,
    options?: boolean | EventListenerOptions
  ): void {
    this._eventTarget.removeEventListener(type, listener, options)
  }

  dispatchEvent(event: Event): boolean {
    return this._eventTarget.dispatchEvent(event)
  }

  protected emitEvent(type: string, detail: unknown): void {
    this.dispatchEvent(
      new CustomEvent(type, {
        detail,
      })
    )
  }

  protected getInterpolatorOptions(): TTerminologyInterpolatorOptions {
    const options = super.getInterpolatorOptions()
    options.postProcessorChains = options.postProcessorChains as Record<string, IPostProcessor[]>
    options.postProcessorChains[BrowserClient.HTML] = [
      ...options.postProcessorChains[Interpolator.DEFAULT],
      this._htmlPostProcessor,
    ]
    return options
  }

  protected handleMessageKeyMissing(evt: Event): void {
    const { detail } = evt as CustomEvent
    const namespaces = detail.namespaces.map((ns: string) => '"' + ns + '"').join(', ')
    console.error(
      `The key "${detail.key}" was not found in ${namespaces} namespace(s). Fix that to avoid network calls.`
    )
  }

  protected handleError(evt: Event): void {
    const { detail } = evt as CustomEvent
    console.error(
      `Got "${detail.message}" during "${detail.op}" operation. Error caught:`,
      detail.error
    )
  }
}

BrowserClient.HTML = 'HTML'

function isKnownTerm(term: string): term is keyof TTerminology {
  return term in TERMINOLOGY_INFO
}

function getHeaders(): Headers {
  const headers = new Headers()
  const xsrfToken = getXSRFToken()
  if (xsrfToken) {
    headers.append('X-XSRF-TOKEN', xsrfToken)
  }

  const bearerToken = (window as IWindowWithVariables).WF_API_BEARER_TOKEN
  if (bearerToken) {
    headers.append('Authorization', `Bearer ${bearerToken}`)
  }

  return headers
}

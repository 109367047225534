import React from 'react'
import { useLocalizeClient } from './useLocalizeClient'
import { useAsyncResult } from './helpers/useAsyncResult'
import { AbstractClient, BrowserClient, Interpolator } from '@workfront/localize-browser'
import { TLocalizeContext } from './LocalizeContext'
import { areArraysEqual } from './helpers/areArraysEqual'

export type TUseLocalizationContextParam = TLocalizeContext | BrowserClient | undefined
export type TUseLocalizationsReturnValue = [_t: Record<string, string>, isLoaded: boolean]
export type TMessagesMap = Record<string, TMessagesMapValue>
export type TMessagesMapValue = {
  messageKey: string
  fallback: string
  args?: unknown[] | Record<string, unknown>
}
export type TNormalizedMessagesMapValue = Omit<TMessagesMapValue, 'args'> & {
  interpolationArgs: unknown[]
}
export type TUseLocalizationsArgs =
  | [messagesMap: TMessagesMap]
  | [context: TUseLocalizationContextParam, messagesMap: TMessagesMap]

/**
 * React hook, loads and returns an array of
 * object containing localized interpolated messages and 'isLoading' boolean
 */
function useLocalizationsGeneric(
  postProcessorChain: string,
  messagesMap: TMessagesMap
): TUseLocalizationsReturnValue
function useLocalizationsGeneric(
  postProcessorChain: string,
  context: TUseLocalizationContextParam,
  messagesMap: TMessagesMap
): TUseLocalizationsReturnValue

function useLocalizationsGeneric(
  postProcessorChain: string,
  ...args: TUseLocalizationsArgs
): TUseLocalizationsReturnValue {
  let context: TUseLocalizationContextParam
  let messagesMap: TMessagesMap

  const [firstArg, secondArg] = args
  if (secondArg === undefined) {
    messagesMap = firstArg as TMessagesMap
  } else {
    context = firstArg as TUseLocalizationContextParam
    messagesMap = secondArg
  }

  const client = useLocalizeClient(context)

  const messageKeys = collectMessageKeysFromMap(messagesMap)
  const action = useLoadMessageKeys(client, messageKeys)
  const alreadyLoaded = client.getStore().containsAll(messageKeys)
  const isLoaded = useAsyncResult<void | boolean>(action, alreadyLoaded, alreadyLoaded)[1]

  const interpolatedMessages: Record<string, string> = {}
  for (const key in messagesMap) {
    const { fallback, messageKey, interpolationArgs } = normalizeMessagesMapValue(messagesMap[key])
    const message = getMessage(client, isLoaded, messageKey, fallback)
    interpolatedMessages[key] = client
      .getInterpolator()
      .interpolateUsingChain(postProcessorChain, client.getLocale(), message, ...interpolationArgs)
  }
  return [interpolatedMessages, isLoaded]
}

export function normalizeMessagesMapValue(val: TMessagesMapValue): TNormalizedMessagesMapValue {
  return {
    fallback: val.fallback,
    messageKey: val.messageKey,
    interpolationArgs: Array.isArray(val.args) ? val.args : [val.args],
  }
}

export function collectMessageKeysFromMap(messagesMap: TMessagesMap): string[] {
  const messageKeys: string[] = []
  for (const key in messagesMap) {
    messageKeys.push(messagesMap[key].messageKey)
  }
  return messageKeys
}

export function getMessage(
  client: AbstractClient,
  isLoaded: boolean,
  messageKey: string,
  fallback: string
) {
  if (isLoaded) {
    return client.getStore().find(messageKey, fallback).message
  }
  return fallback || messageKey
}

export function useLoadMessageKeys(client: AbstractClient, messageKeys: string[]) {
  const messageKeysRef = React.useRef(messageKeys)
  if (!areArraysEqual(messageKeys, messageKeysRef.current)) {
    messageKeysRef.current = messageKeys
  }
  const currentMessageKeys = messageKeysRef.current
  return React.useCallback(() => client.load(currentMessageKeys), [client, currentMessageKeys])
}

function useLocalizations(messagesMap: TMessagesMap): TUseLocalizationsReturnValue
function useLocalizations(
  context: TUseLocalizationContextParam,
  messagesMap: TMessagesMap
): TUseLocalizationsReturnValue
function useLocalizations(...args: TUseLocalizationsArgs): TUseLocalizationsReturnValue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useLocalizationsGeneric(Interpolator.DEFAULT, ...args)
}

function useLocalizationsHtml(messagesMap: TMessagesMap): TUseLocalizationsReturnValue
function useLocalizationsHtml(
  context: TUseLocalizationContextParam,
  messagesMap: TMessagesMap
): TUseLocalizationsReturnValue
function useLocalizationsHtml(...args: TUseLocalizationsArgs): TUseLocalizationsReturnValue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useLocalizationsGeneric(BrowserClient.HTML, ...args)
}

export { useLocalizationsGeneric, useLocalizations, useLocalizationsHtml }

import { MultiKeyMap } from '../helpers/MultiKeyMap'
import { DEFAULT_LOCALE } from '../constants'

export class MessageFinder {
  protected _namespaces: string[]
  protected _store: MultiKeyMap<3, string, string>

  constructor(namespaces: string[], store: MultiKeyMap<3, string, string>) {
    this._namespaces = namespaces
    this._store = store
  }

  /**
   * Lookup a value for the given message key in the underlying store,
   * using the best possible locale from the provided locale list.
   * Returns fallback string if message key cannot be found.
   * Returns 'undefined' as a locale if message key was not found.
   */
  find(
    locales: string | string[],
    messageKey: string,
    fallback: string
  ): { message: string; locale?: string } {
    locales = Array.isArray(locales) ? locales : [locales]
    if (!locales.includes(DEFAULT_LOCALE)) {
      locales = [...locales, DEFAULT_LOCALE]
    }

    // For each namespace we find first locale with the given message key,
    // and use the namespace with the lowest locale index.
    // That way we find the namespace with the closest (in terms of locale fallback) translation.
    let foundMessage: string | undefined = undefined
    let lowestLocaleIndex = -1
    for (const namespace of this._namespaces) {
      let foundLocaleIndex = -1
      for (let j = 0; j < locales.length; ++j) {
        const message = this._store.get([namespace, locales[j], messageKey])
        if (message !== undefined) {
          foundLocaleIndex = j
          if (foundLocaleIndex < lowestLocaleIndex || lowestLocaleIndex === -1) {
            lowestLocaleIndex = foundLocaleIndex
            foundMessage = message
          }
          break
        }
      }
    }

    const foundLocale = lowestLocaleIndex < 0 ? undefined : locales[lowestLocaleIndex]
    if (!foundMessage && fallback) {
      foundMessage = fallback
    }
    if (foundMessage === undefined) {
      foundMessage = this.handleMissingMessage(messageKey)
    }
    return { message: foundMessage, locale: foundLocale }
  }

  /**
   * Returns true if all the given message keys exist for the provided locale
   */
  containsAll(locale: string, messageKeys: string[]): boolean {
    return messageKeys.every((key) => this.find(locale, key, '?').locale === locale)
  }

  /**
   * Override to provide custom implementation for handling the case when
   * the given messageKey cannot be found in any of provided namespaces.
   */
  protected handleMissingMessage(messageKey: string): string {
    return `?${messageKey}?`
  }
}

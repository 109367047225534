import React from 'react'

/**
 * React hook, calls factory to create an instance,
 * and does that only once per component.
 * Returns that instance.
 */
export function useInstance<T>(factory: () => T): [T, React.MutableRefObject<T | undefined>] {
  const ref = React.useRef<T>()
  let instance = ref.current
  if (!instance) {
    instance = factory()
    ref.current = instance
  }
  return [instance, ref]
}

import { namespace, namespacePrefix } from './custom-terminology-metadata/terminology.json'

export const DEFAULT_LOCALE = 'en'

export const TERMINOLOGY_NS_PREFIX = namespacePrefix

export const TERMINOLOGY_DEFAULTS_NS = namespace

export const SUPPORTED_LOCALES = [
  'en', // en-US
  'de', // de-DE
  'es', // es-ES
  'fr', // fr-FR
  'it', // it-IT
  'ja', // ja-JP
  'pt-BR', // pt-BR
  'ko', // ko-KR
  'zh', // zh-Hans-CN (Chinese Simplified)
  'zh-TW', // zh-Hant-TW (Chinese Traditional)
]

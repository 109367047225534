import React from 'react'

/**
 * React hook, returns true if component is mounted, false otherwise
 */
export function useIsMounted(): React.MutableRefObject<boolean> {
  const isMountedRef = React.useRef(false)
  React.useLayoutEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])
  return isMountedRef
}

import type { ICachedValue, ICacheQueryResult, IAsyncCache } from './IAsyncCache'
import { MultiKeyMap } from '../helpers/MultiKeyMap'
import { objectForEach } from '../helpers/objectForEach'

export class InMemoryCache implements IAsyncCache {
  protected _storage: MultiKeyMap<3, string, ICachedValue>

  constructor() {
    this._storage = this.createStorage()
  }

  get(namespace: string, locale: string, messageKeys: string[]): Promise<ICacheQueryResult> {
    const cacheQueryResult: ICacheQueryResult = {
      hits: {},
      expiredKeys: [],
      missingKeys: [],
    }

    const now = +new Date()
    for (const messageKey of messageKeys) {
      const cacheKey: [string, string, string] = [namespace, locale, messageKey]
      const cachedValue = this._storage.get(cacheKey)
      if (cachedValue) {
        cacheQueryResult.hits[messageKey] = cachedValue
        if (cachedValue.expiresAt < now) {
          cacheQueryResult.expiredKeys.push(messageKey)
        }
      } else {
        cacheQueryResult.missingKeys.push(messageKey)
      }
    }

    return Promise.resolve(cacheQueryResult)
  }

  set(
    namespace: string,
    locale: string,
    messages: Record<string, string>,
    expiresAt: number
  ): Promise<void> {
    objectForEach(messages, (message, messageKey) => {
      this._storage.set([namespace, locale, messageKey], {
        message,
        expiresAt,
      })
    })
    return Promise.resolve()
  }

  remove(namespace: string, locale: string, messageKeys: string[]): Promise<void> {
    for (const messageKey of messageKeys) {
      this._storage.remove([namespace, locale, messageKey])
    }
    return Promise.resolve()
  }

  open(): Promise<void> {
    return Promise.resolve()
  }

  close(): Promise<void> {
    return Promise.resolve()
  }

  isOpen(): boolean {
    return true
  }

  protected createStorage(): MultiKeyMap<3, string, ICachedValue> {
    return new MultiKeyMap<3, string, ICachedValue>(3)
  }
}

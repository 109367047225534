import React from 'react'
import { BrowserClient } from '@workfront/localize-browser'

export type TLocalizeContext<T extends BrowserClient = BrowserClient> = React.Context<T | undefined>

export const LocalizeContext: TLocalizeContext = createLocalizeContext()

export function createLocalizeContext(): TLocalizeContext {
  return React.createContext<BrowserClient | undefined>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (BrowserClient as any).defaultInstance
  )
}

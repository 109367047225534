import type { ICachedValue } from './IAsyncCache'
import { MultiKeyMap } from '../helpers/MultiKeyMap'
import { InMemoryCache } from './InMemoryCache'

// keep cache globally to share it between multiple instances of client libraries
const cacheStorageKey = 'wfLocalizeInMemoryCacheStorage'
const globalScope = globalThis as unknown as Record<
  typeof cacheStorageKey,
  MultiKeyMap<3, string, ICachedValue>
>

export class SharedInMemoryCache extends InMemoryCache {
  static clear(): void {
    if (globalScope[cacheStorageKey]) {
      globalScope[cacheStorageKey].clear()
    }
  }

  protected createStorage(): MultiKeyMap<3, string, ICachedValue> {
    if (!globalScope[cacheStorageKey]) {
      globalScope[cacheStorageKey] = new MultiKeyMap<3, string, ICachedValue>(3)
    }
    return globalScope[cacheStorageKey]
  }
}

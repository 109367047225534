export function makeTimeBoundedPromise<T>(
  promise: Promise<T>,
  options: {
    timeout?: number
    abort?: () => unknown
    timeoutRejectValue?: unknown
  }
): Promise<T> {
  const { timeout = 10000, abort, timeoutRejectValue } = options
  return new Promise((resolve, reject) => {
    let timeoutPassed = false
    const timeoutHandle = setTimeout(function () {
      timeoutPassed = true
      abort && abort()
      reject(timeoutRejectValue)
    }, timeout)

    promise.then(
      (result) => {
        if (!timeoutPassed) {
          resolve(result)
          clearTimeout(timeoutHandle)
        }
      },
      (err) => {
        if (!timeoutPassed) {
          reject(err)
          clearTimeout(timeoutHandle)
        }
      }
    )
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayToObject(args: any[]): Record<string, unknown> {
  if (args.length === 1 && typeof args[0] === 'object' && !(args[0] instanceof Date)) {
    return args[0]
  } else {
    return args.reduce((acc, val, i) => {
      acc[i] = val
      return acc
    }, {})
  }
}

/**
 * Like Map.prototype.forEach, but for objects
 */
export function objectForEach<K extends string, T>(
  obj: Partial<Record<K, T>>,
  cb: (val: T, key: K) => void
): void {
  Object.keys(obj).forEach((key) => {
    cb(obj[key as K] as T, key as K)
  })
}

import { ITransportParam, ITransportResult, TTransportResponse } from './ITransport'
import { MultiKeyMap, Tuple } from '../helpers/MultiKeyMap'
import { DEFAULT_LOCALE } from '../constants'
import { TServiceRequest, TServiceRequestParam } from './TServiceRequest'

/**
 * This character cannot appear in both locale and namespace
 */
const DELIMITER = '/'

export function encode(locale: string, namespace: string, key: string): string {
  return locale + DELIMITER + namespace + DELIMITER + key
}

export function decode(encoded: string): {
  locale: string
  namespace: string
  key: string
} {
  const [locale, namespace] = encoded.split(DELIMITER, 2)
  return {
    locale,
    namespace,
    key: encoded.substring(locale.length + DELIMITER.length + namespace.length + DELIMITER.length),
  }
}

export type TFlattenedTransportResponse = {
  maxCacheAge: number

  // a map of encoded "locale/namespace/key" tuple and the message
  flattenedResults: Map<string, string>
}

export function flattenTransportResponses(
  responses: TTransportResponse[]
): TFlattenedTransportResponse {
  const flattenedResults = new Map<string, string>()
  let maxCacheAge = 0
  for (const response of responses) {
    if (response.maxCacheAge > maxCacheAge) {
      maxCacheAge = response.maxCacheAge
    }
    for (const { namespace, locale, messages } of response.results) {
      for (const key of Object.keys(messages)) {
        flattenedResults.set(encode(locale, namespace, key), messages[key])
      }
    }
  }
  return {
    maxCacheAge,
    flattenedResults,
  }
}

export function extractRequestedTuplesFromFlattenedTransportResponse(
  flattenedResponse: TFlattenedTransportResponse,
  requestedTuples: string[]
): TTransportResponse {
  const response: TTransportResponse = {
    maxCacheAge: flattenedResponse.maxCacheAge,
    results: [],
  }

  // this lookup table is used for simplify lookups in `response.results`
  const resultsLookupTable = new MultiKeyMap<2, string, ITransportResult[]>(2)

  // find a message for each requested <locale, namespace, key> tuple
  // to prepare a response for the requested args
  for (const encodedKey of requestedTuples) {
    const decoded = decode(encodedKey)
    let responseLocale = decoded.locale
    const namespace = decoded.namespace
    const key = decoded.key
    let message = flattenedResponse.flattenedResults.get(encodedKey)

    // if message is missing from results of requested locale
    // check if it was returned in DEFAULT_LOCALE
    if (message === undefined && responseLocale !== DEFAULT_LOCALE) {
      message = flattenedResponse.flattenedResults.get(encode(DEFAULT_LOCALE, namespace, key))
      responseLocale = DEFAULT_LOCALE
    }

    if (message !== undefined) {
      const resultsLookupKey: Tuple<string, 2> = [responseLocale, namespace]
      const resultsToAmend = resultsLookupTable.ensure(resultsLookupKey, () => {
        const entry = {
          namespace,
          locale: responseLocale,
          messages: {},
        }
        response.results.push(entry)
        return [entry]
      })

      for (const res of resultsToAmend) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        res.messages[key] = message!
      }
    }
  }
  return response
}

/**
 * Returns an array of "locale/namespace/key" tuples representing
 * data contained in the given service request
 */
export function encodeTransportArgs(locale: string, params: ITransportParam[]): string[] {
  const encodedTuples: string[] = []
  for (const param of params) {
    for (const key of param.keys) {
      encodedTuples.push(encode(locale, param.namespace, key))
    }
  }
  return encodedTuples
}

/**
 * Sorts array of "locale/namespace/key" tuples by locale and namespace,
 * without taking the order of keys into account.
 * This method exists, because sorted tuple array is easier to process.
 */
export function sortTuples(tuples: string[]): string[] {
  return tuples.sort((a, b) => {
    const [localeA, namespaceA] = a.split(DELIMITER, 2)
    const [localeB, namespaceB] = b.split(DELIMITER, 2)

    const localeCompareResult = localeA.localeCompare(localeB)

    // if locales are equal, compare namespaces
    if (localeCompareResult === 0) {
      return namespaceA.localeCompare(namespaceB)
    }

    return localeCompareResult
  })
}

/**
 * Takes an array of "locale/namespace/key" tuples as an input, and returns an
 * array of service requests - objects which can be directly sent to the server.
 *
 * Takes into account limitations of maximum number of keys in a single namespace (keysPerNamespace)
 * and of maximum number of params in a single service request (paramsPerRequest).
 */
export function decodeServiceRequests(
  tuples: string[],
  keysPerNamespace = -1,
  paramsPerRequest = -1
): TServiceRequest[] {
  tuples = sortTuples(tuples)
  const serviceRequests: TServiceRequest[] = []
  let serviceRequest: TServiceRequest = {
    locale: '',
    params: [],
  }
  let serviceRequestParam: TServiceRequestParam = {
    namespace: '',
  }

  let collectedKeysPerNamespace = 0
  const keyUsagePerServiceRequestParam = new Map<string, Set<TServiceRequestParam>>()

  function maybePushKey<T>(obj: { keys?: T[] }, key: T) {
    if (obj.keys) {
      obj.keys.push(key)
    } else {
      obj.keys = [key]
    }
  }

  const finalizeChunk = (_serviceRequest: TServiceRequest) => {
    if (_serviceRequest.locale) {
      serviceRequests.push(_serviceRequest)
      for (const [key, serviceRequestParamsSet] of keyUsagePerServiceRequestParam) {
        if (serviceRequestParamsSet.size === _serviceRequest.params.length) {
          maybePushKey(_serviceRequest, key)
        } else {
          for (const _serviceRequestParam of serviceRequestParamsSet) {
            maybePushKey(_serviceRequestParam, key)
          }
        }
      }
      keyUsagePerServiceRequestParam.clear()
    }
  }

  for (const encodedTuple of tuples) {
    const { locale, namespace, key } = decode(encodedTuple)

    if (
      namespace !== serviceRequestParam.namespace ||
      collectedKeysPerNamespace === keysPerNamespace
    ) {
      if (serviceRequestParam.namespace) {
        serviceRequest.params.push(serviceRequestParam)
      }
      serviceRequestParam = {
        namespace,
      }
      collectedKeysPerNamespace = 0
    }

    if (locale !== serviceRequest.locale || serviceRequest.params.length === paramsPerRequest) {
      finalizeChunk(serviceRequest)
      serviceRequest = {
        locale,
        params: [],
      }
    }

    ++collectedKeysPerNamespace
    let params = keyUsagePerServiceRequestParam.get(key)
    if (!params) {
      params = new Set<ITransportParam>()
      keyUsagePerServiceRequestParam.set(key, params)
    }
    params.add(serviceRequestParam)
  }

  if (serviceRequestParam.namespace) {
    serviceRequest.params.push(serviceRequestParam)
  }
  finalizeChunk(serviceRequest)

  return serviceRequests
}

import MessageFormat from 'message-format'
import { IPostProcessor } from './IPostProcessor'
import { arrayToObject } from '../helpers/arrayToObject'

export type TInterpolatorOptions = {
  postProcessorChains?: Record<string, IPostProcessor[]>
}
export class Interpolator {
  /**
   * Name of default post-processor chain
   */
  static DEFAULT: 'default'

  protected _postProcessorChains: Record<string, IPostProcessor[]>

  constructor(options?: TInterpolatorOptions) {
    if (options && options.postProcessorChains) {
      this._postProcessorChains = options.postProcessorChains
    } else {
      this._postProcessorChains = {}
    }
    this._postProcessorChains[Interpolator.DEFAULT] =
      this._postProcessorChains[Interpolator.DEFAULT] || []

    //check if only the last post-processor in each chain has isLast flag set
    for (const postProcessors of Object.values(this._postProcessorChains)) {
      const flags = postProcessors.filter((postProcessor) => postProcessor.isLast())
      if (
        flags.length > 1 ||
        (flags.length === 1 && !postProcessors[postProcessors.length - 1].isLast())
      ) {
        throw new Error(
          'Post-processor with isLast flag set should be the last post-processor in chain.'
        )
      }
    }
  }

  interpolate(locale: string, message: string, ...args: unknown[]): string {
    return this.interpolateUsingChain(Interpolator.DEFAULT, locale, message, ...args)
  }

  interpolateUsingChain(
    postProcessorChainType: string,
    locale: string,
    message: string,
    ...args: unknown[]
  ): string {
    return this._postProcessorChains[postProcessorChainType].reduce(
      (msg, postProcessor) => postProcessor.run(msg),
      this.interpolationEngine(locale, message, args)
    )
  }

  /**
   * Override in case you need an alternative interpolation engine
   */
  protected interpolationEngine(locale: string, message: string, args: unknown[]): string {
    // Return message immediately if it does not contain ICU Message Format syntax,
    // because `String::includes` is ultra-fast in comparison with `MessageFormat::format`.
    // Benchmark results:
    // without check x    136,129 ops/sec ±1.07% (83 runs sampled)
    //    with check x 31,674,029 ops/sec ±0.81% (94 runs sampled)
    if (!message.includes('{') && !message.includes("'")) {
      return message
    }
    return new MessageFormat(message, locale).format(arrayToObject(args))
  }
}

Interpolator.DEFAULT = 'default'

import React from 'react'
import { useIsMounted } from './useIsMounted'

type TUseAsyncResultReturnValue<R> = [R, boolean]

/**
 * React hook, performs an async action and returns a pair of 'result' and 'isLoaded' boolean.
 * If 'alreadyLoaded' param is passed, synchronously returns 'initialValue'.
 *
 * This hook executes action only if 'alreadyLoaded' is set to false.
 */
export function useAsyncResult<R>(
  action: () => Promise<R>,
  initialValue: R,
  alreadyLoaded = false
): TUseAsyncResultReturnValue<R> {
  // The purpose of keeping result in state is to trigger re-render when result is being set
  // As result from previous action can be a primitive, setting it to the same value
  // won't trigger re-render.
  // Because of that we store result in a single element array and update the array to set the result.
  // As array reference changes every time, that causes re-render as needed.
  const [result, setResult] = React.useState<[R]>([initialValue])

  // use ref to store isLoaded state, to avoid re-renders when only
  // that state changes, which happens when result[0] == initialValue
  const isLoadedRef = React.useRef(false)

  // reset isLoaded state when `action` changes,
  // otherwise isLoaded will show state of previous `action`
  const actionRef = React.useRef(action)
  if (actionRef.current !== action) {
    isLoadedRef.current = false
  }

  const isMountedRef = useIsMounted()
  React.useEffect(() => {
    if (alreadyLoaded) {
      return
    }
    const cancelablePromise = {
      promise: action().then((res) => {
        if (isMountedRef.current && !cancelablePromise.isCancelled) {
          isLoadedRef.current = true
          setResult([res])
        }
      }),
      isCancelled: false,
    }
    return () => {
      cancelablePromise.isCancelled = true
    }
  }, [action, alreadyLoaded, isMountedRef])

  if (alreadyLoaded) {
    return [initialValue, true]
  }
  return isLoadedRef.current ? [result[0], true] : [initialValue, false]
}

export type TMapOrValue<K, V> = Map<K, TMapOrValue<K, V> | V>

export type Tuple<T, L extends number> = [T, ...T[]] & { length: L }

/**
 * A Map implementation where key is an n-tuple - array of strings having length `n`
 */
export class MultiKeyMap<L extends number, K, V> {
  protected _storage: TMapOrValue<K, V>
  protected _keyLength: number

  constructor(keyLength: L) {
    this._storage = new Map()
    this._keyLength = keyLength
  }

  /**
   * Returns the value associated to the key, or undefined if there is none.
   */
  get(keyParts: Tuple<K, L>): V | undefined {
    const ptr = this._movePointerTo(keyParts, () => undefined)
    if (ptr) {
      return ptr.get(keyParts[keyParts.length - 1]) as V
    }
  }

  /**
   * Returns value stored under then given key if it exists.
   * Otherwise creates a new value using missingValueCreator, stores it, and returns it.
   */
  ensure(keyParts: Tuple<K, L>, missingValueCreator: () => V): V {
    if (this.has(keyParts)) {
      return this.get(keyParts) as V
    } else {
      const value = missingValueCreator()
      this.set(keyParts, value)
      return value
    }
  }

  /**
   * Returns a boolean asserting whether a value has been associated to the key in the Map object or not.
   */
  has(keyParts: Tuple<K, L>): boolean {
    const ptr = this._movePointerTo(keyParts)
    return !!ptr && ptr.has(keyParts[keyParts.length - 1])
  }

  /**
   * Sets the value for the key in the MultiKeyMap object.
   */
  set(keyParts: Tuple<K, L>, value: V): void {
    const ptr = this._movePointerTo(keyParts, (currPtr, key, keyExists) => {
      if (!keyExists) {
        const nextPtr = new Map()
        currPtr.set(key, nextPtr)
        return nextPtr
      }
    })
    ;(ptr as Map<K, V>).set(keyParts[keyParts.length - 1], value)
  }

  /**
   * Removes element identified by the given key.
   */
  remove(keyParts: Tuple<K, L>): void {
    const pointers: Array<[TMapOrValue<K, V>, K]> = []
    const ptr = this._movePointerTo(keyParts, (currPtr, key, keyExists) => {
      if (keyExists) {
        pointers.push([currPtr, key])
      }
    })
    if (ptr) {
      ptr.delete(keyParts[keyParts.length - 1])
      for (
        let info = pointers.pop();
        info && !(info[0].get(info[1]) as Map<K, never>).size;
        info = pointers.pop()
      ) {
        info[0].delete(info[1])
      }
    }
  }

  /**
   * Removes all entries
   */
  clear(): void {
    this._storage.clear()
  }

  /**
   * Returns a new iterator object that contains the [key, value] pairs for each element
   */
  entries(): IterableIterator<[Tuple<K, L>, V]> {
    return this._forEachRecurse([] as K[], this._storage)
  }

  protected _movePointerTo(
    keyParts: Tuple<K, L>,
    visitor: (
      ptr: TMapOrValue<K, V>,
      key: K,
      missing?: boolean
    ) => TMapOrValue<K, V> | void = () => {}
  ): TMapOrValue<K, V> | undefined {
    let ptr = this._storage
    for (let i = 0; i < keyParts.length - 1; ++i) {
      const key = keyParts[i]
      const keyExists = ptr.has(key)
      const createdPtr = visitor(ptr, key, keyExists)
      if (keyExists) {
        ptr = ptr.get(key) as TMapOrValue<K, V>
      } else if (createdPtr) {
        ptr = createdPtr
      } else {
        return
      }
    }
    return ptr
  }

  protected *_forEachRecurse(
    keyParts: K[],
    ptr: TMapOrValue<K, V>
  ): IterableIterator<[Tuple<K, L>, V]> {
    for (const [key, val] of ptr) {
      keyParts.push(key)
      if (keyParts.length === this._keyLength) {
        yield [[...keyParts] as Tuple<K, L>, val as V]
      } else {
        for (const item of this._forEachRecurse(keyParts, val as TMapOrValue<K, V>)) {
          yield item
        }
      }
      keyParts.pop()
    }
  }
}

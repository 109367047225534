import { SharedInMemoryCache, IAsyncCache } from '@workfront/localize'

import type { TAbstractTerminologyEnabledClientOptions, ITransport } from '@workfront/localize'
import {
  TUserDefinedL10nFetchTransportOptions,
  UserDefinedL10nFetchTransport,
} from './UserDefinedL10nFetchTransport'
import { BrowserClient } from './BrowserClient'
import { Interpolator, NoopInterpolator } from '@workfront/localize'

export type TUserDefinedL10nBrowserClientOptions = Omit<
  TAbstractTerminologyEnabledClientOptions,
  'transport'
> &
  Partial<Pick<TAbstractTerminologyEnabledClientOptions, 'transport'>> & {
    /**
     * A duration transport waits collecting requests in a buffer
     * until making actual call to transport for unbuffered transfer
     */
    flushDelayMs?: number
  }

function getTransportInstance(options: TUserDefinedL10nFetchTransportOptions): ITransport {
  return new UserDefinedL10nFetchTransport(options)
}

const DEFAULT_TRANSPORT_FLUSH_DELAY = 100

export class UserDefinedL10nBrowserClient extends BrowserClient {
  static defaultInstance?: UserDefinedL10nBrowserClient

  constructor(options: TUserDefinedL10nBrowserClientOptions) {
    const { flushDelayMs = DEFAULT_TRANSPORT_FLUSH_DELAY } = options

    super({
      ...options,
      transport: getTransportInstance({
        flushDelayMs,
      }),
    })
  }
  protected createCache(): Promise<IAsyncCache> {
    return Promise.resolve(new SharedInMemoryCache())
  }

  protected createInterpolator(): Interpolator {
    return new NoopInterpolator()
  }
}

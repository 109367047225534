import DOMPurify, { Config } from 'dompurify'
import type { IPostProcessor } from '@workfront/localize'

export type THtmlPostProcessorOptions = {
  returnTrustedType?: boolean
}

export class HtmlPostProcessor implements IPostProcessor {
  protected _returnTrustedType: boolean
  protected _config: Config & { RETURN_DOM_FRAGMENT?: false; RETURN_DOM?: false }
  protected _domPurifyInstance: DOMPurify.DOMPurifyI

  constructor(options: THtmlPostProcessorOptions = {}) {
    this._returnTrustedType = !!(options.returnTrustedType && supportsTrustedTypes())
    this._config = {
      ALLOWED_TAGS: [
        'p',
        'b',
        'ul',
        'li',
        'a',
        'br',
        'strong',
        'em',
        'span',
        'i',
        '#text', // keep text nodes
      ],

      // keep content of non-white-listed nodes
      KEEP_CONTENT: true,

      // prohibit HTML5 data attributes
      ALLOW_DATA_ATTR: false,

      ALLOWED_ATTR: ['href', 'target', 'class'],

      // do not strip {{ ... }} and <% ... %> (to make output safe for template systems)
      SAFE_FOR_TEMPLATES: false,

      RETURN_TRUSTED_TYPE: this._returnTrustedType,
    }
    this._domPurifyInstance = DOMPurify()
    this._domPurifyInstance.setConfig(this._config)
  }

  isLast(): boolean {
    return this._returnTrustedType
  }

  run(message: string): string {
    return this._domPurifyInstance.sanitize(message, this._config)
  }
}

function supportsTrustedTypes(): boolean {
  return (
    window &&
    typeof window.trustedTypes === 'object' &&
    typeof window.trustedTypes.createPolicy === 'function'
  )
}
